import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/Layout"
import Banner from "../components/Banner"
import Testimonials from "../components/Testimonials"

const getTreatments = graphql`
  query {
    fileName: file(relativePath: { eq: "banner-home.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default () => {
  const data = useStaticQuery(getTreatments)

  return (
    <Layout>
      <Banner
        title="Terms and conditions"
        ishome={false}
        image={data.fileName.childImageSharp.fluid}
      />
      <main>
        <div className="main-content">
          <div className="c-content">
            <div className="container">
              <div className="row">
                <div className="c-content__text">
                  <p>We require a minimum of 24 hours notice of cancellation of any treatment and regret that failure to do so will result in you being charged the full amount.</p>
                  <p>Please be aware, that if at the time of your appointment you are under the influence of alcohol or recreational drugs, the practitioner reserves the right to refuse treatment without a refund. Even if the appointment is to address addiction, we ask all patients to arrive sober.</p>
                  <p><strong>Refunds policy</strong></p>
                  <p>As highlighted above, if your appointment is cancelled less than 24 hours before it should take place, or you do not show up for your appointment time, you will not be eligible for a refund. If you arrive late up to 15min, your treatment can still be carried out however, shortened and finished on time.</p>
                  <p>if you have pre-booked a series of appointments, when cancelling, you will be refunded for the unused appointments, assuming the appointments were cancelled more than 24 hours in advance.</p>
                  <p>If a discount was applied, for example when ordering 3, 6 or more appointments, the discount will be invalid, and the price of the used appointments will be calculated based on the regular full price and you will be refunded the difference.</p>
                  <p><strong>Client Etiquette</strong></p>
                  <p>We pride ourselves at Step2Wellbeing on our professionalism and expertise. We will not accept any behaviour which we deem to be threatening, propositioning or with any sexual innuendos. Any behaviour which leads to the therapist feeling uncomfortable will result in the treatment being terminated immediately and reported to the authorities.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Testimonials />
    </Layout>
  )
}
